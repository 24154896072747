<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :setting-columns="columns"
        :filters.sync="filters"
        :endpoint-list="ENDPOINT.TRANSPORTS_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <label>{{ filters.status_booking.label }}</label>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <app-radio v-for="(item, index) in TRANSPORTS_LIST_CONSTANT.FILTER_STATUS_BOOKING"
                               :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                               :val="item.id" v-model="filters.status_booking.value"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group start_date">
                <label>{{ filters.apartment_id_equal.label }}</label>
                <div class="d-flex justify-content-between">
                  <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                    <app-select :name="filters.apartment_id_equal.name"
                                input-style="normal"
                                v-model="filters.apartment_id_equal.value"
                                :options-data="meta.apartments" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3">
              <div class="form-group start_date">
                <label>{{ filters.transport_car_id_equal.label }}</label>
                <app-select :name="filters.transport_car_id_equal.name"
                            input-style="normal"
                            v-model="filters.transport_car_id_equal.value"
                            :options-data="meta.transport_car"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-sm-4 pl-0">
              <div class="form-group start_date">
                <label class="col-xl-4 col-lg-6 col-sm-8">{{ filters.start_date.label }}</label>
                <div class="d-flex justify-content-between">
                  <div class="col-xl-9 col-lg-12 col-sm-12">
                    <app-date-range-picker
                      :rules="`from_date_time_list_page:${filters.close_date.value}`"
                      :name="filters.start_date.name"
                      v-model="filters.start_date.value"
                      format="YYYY-MM-DD H:mm"
                      :timePickerMinutes="true"
                      @input="filters.start_date.value = $event"/>
                  </div>
                  <span class="align-self-center align-items-center mr-lg-2 mr-xl-5" style="font-size: 30px">~</span>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group">
                <label class="col-xl-4 col-lg-4 col-sm-8" style="opacity: 0">1</label>
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-date-range-picker
                    :name="filters.close_date.name"
                    v-model="filters.close_date.value"
                    format="YYYY-MM-DD H:mm"
                    :timePickerMinutes="true"
                    @input="filters.close_date.value = $event"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group start_date">
                <label>{{ filters.customer_name_booking.label }}</label>
                <div class="d-flex justify-content-between">
                  <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                    <app-input :name="filters.customer_name_booking.name" input-style="normal"
                               v-model="filters.customer_name_booking.value"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group start_date">
                <label>{{ filters.customer_phone.label }}</label>
                <div class="d-flex justify-content-between">
                  <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                    <app-input :name="filters.customer_phone.name"
                               type="phone"
                               input-style="normal"
                               placeholder="00000000000"
                               v-model="filters.customer_phone.value"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-apartment_id="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.apartment_name" style="color: #007bff;"
               v-if="props.row.apartment_id != null"
               @click="handlerApartmentNameClick(props.row.apartment_id)" class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.apartment_name}}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-transport_car_id="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.transport_car_name" style="color: #007bff;"
               v-if="props.row.transport_car_id != null"
               @click="handlerPlanTransportNameClick(props.row.transport_car_id, props.row.apartment_id)" class="facility_name app-table-p app-cell-tooltip mb-0">
              {{props.row.transport_car_name}}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-status_booking="props">
          <td class="app-align-middle">
            <p class=" app-table-p app-cell-tooltip mb-0">
              <app-select @change="updateStatus($event, props.row.id)"
                          input-style="normal"
                          v-model="props.row.status_booking"
                          :options-data="TRANSPORTS_LIST_CONSTANT.STATUS_BOOKING"/>
            </p>
          </td>
        </template>
        <template v-slot:body-cell-address_pickup="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.address_pickup" style="color: #007bff;"
               @click="handlerOpenMap(props.row.location_pickup)" class="facility_name app-table-p app-cell-tooltip mb-0">
              {{ props.row.name_address_pickup }}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-address_dropoff="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.address_dropoff" style="color: #007bff;"
               @click="handlerOpenMap(props.row.location_dropoff)" class="facility_name app-table-p app-cell-tooltip mb-0">
              {{ props.row.name_address_dropoff }}
            </p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {TRANSPORTS_LIST_CONSTANT} from "../../../constants/transport_list";

  export default {
    name: "TransportListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          status_booking: {
            name: "status_booking",
            condition: "equal",
            label: this.$t("transport.status_booking")
          },
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("order_list.mansion")
          },
          transport_car_id_equal: {
            name: "transport_car_id",
            condition: "equal",
            label: this.$t("transport.transport_car_id")
          },
          customer_name_booking: {
            name: "customer_name_booking_like",
            condition: "like",
            label: this.$t("transport.customer_name_booking")
          },
          customer_name_katakana: {
            name: "customer_name_katakana_like",
            condition: "like",
            label: this.$t("transport.customer_name_katakana")
          },
          customer_phone: {
            name: "customer_phone_like",
            condition: "like",
            label: this.$t("transport.customer_phone")
          },
          start_date: {
            name: "start_time_between",
            condition: "between",
            label: this.$t("transport.time_booking")
          },
          close_date: {
            name: "close_time_between",
            condition: "between",
            label: this.$t("reservation.status")
          },
        },
        columns: [
          {name: "id", label: this.$t("plans.id"), sortable: true, textAlign: 'text-center'},
          {name: "status_booking", label: this.$t("transport.status_booking"), sortable: true, textAlign: 'text-center'},
          {name: "apartment_id", label: this.$t("order_list.mansion"), sortable: true},
          {name: "transport_car_id", label: this.$t("transport.transport_car_id"), sortable: true},
          {name: "customer_name_booking", label: this.$t("transport.customer_name_booking"), sortable: true},
          {name: "customer_name_katakana", label: this.$t("transport.customer_name_katakana"), sortable: true},
          {name: "start_time", label: this.$t("transport.time_booking"),sortable: true ,textAlign: 'text-center'},
          {name: "customer_phone", label: this.$t("transport.customer_phone"),  textAlign: 'text-center'},
          {name: "customer_phone_cellular", label: this.$t("transport.customer_phone_cellular"),  textAlign: 'text-center'},
          {name: "address_pickup", label: this.$t("transport.address_pickup"),  textAlign: 'text-center'},
          {name: "address_dropoff", label: this.$t("transport.address_dropoff"),  textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
          transport_car: [],
        },
        entry : {}
      };
    },
    watch : {
      'filters.apartment_id_equal.value': function(value, oldValue) {
        this.getMetaData({apartment_id : value})
      },
      'filters.start_date.value' : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      'filters.close_date.value' : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      handlerApartmentNameClick(apartmentID) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartmentID}});
        window.open(routeApartment.href, '_blank');
      },
      handlerPlanTransportNameClick(transportCarID, apartmentID) {
        this.__setLocalStorage('apartment_id', apartmentID);
        let routePlanTransport = this.$router.resolve({name: this.ROUTES.ADMIN.TRANSPORTS_EDIT, params: {id: transportCarID}});
        window.open(routePlanTransport.href, '_blank');
      },
      handlerOpenMap(address) {
        window.open(address);
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.status_booking.equal' : 'all'
          }
        })
      },
      onSearch() {

      },
      getStatus(typeID) {
        let type = TRANSPORTS_LIST_CONSTANT.STATUS_BOOKING.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      getMetaData(params) {
        this.$request.get(this.ENDPOINT.UI_HELPER_TRANSPORTS_LIST, params).then(res => {
          this.meta = {
            apartments: res.data.apartments,
            transport_car: Object.assign([], Object.values(res.data.transport_car).sort(function (a, b) {
              return b.id - a.id
            }))
          }
          this.meta.apartments.unshift({
            id: "",
            name: ""
          })
          this.meta.transport_car.unshift({
            id: "",
            name: ""
          })
        })
      },
      async updateStatus(e, id) {
        await this.$request.get(this.ENDPOINT.TRANSPORTS_LIST_EDIT(id)).then(res => {
          if (res.hasErrors()) {
            return ;
          }
          this.entry = res.data.data
        })
        if (this.entry !== e) {
          let res = null;
          let msg = "";
          res = await this.$request.patch(this.ENDPOINT.TRANSPORTS_LIST_EDIT(id), {
            status_booking : e
          });
          msg = this.$t("common.msg update ok")
          if (res.hasErrors()) {
            this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.__noticeSuccess(msg);
          }
        }
      }
    },
    mounted: function () {

    },
  }
</script>

<style scoped>

</style>
